import { generateId, oneSignalSendExternalUserId } from "../utils";

export const getWatchlistFromLocalstorage = async () => {
    let watchlist = await JSON.parse(localStorage.getItem('watchlist'));
    return !watchlist ? [] : Array.from( new Set(watchlist) );
}
export const addToWatchlistLocalStorage = async movieTranslation => {
    let watchlist = await getWatchlistFromLocalstorage()
    const isItemIn = watchlist.filter(item => item.id === movieTranslation.id)
    
    // Edge case: If user removes it and puts it again do nothing, movie still in props
    if (isItemIn && !isItemIn.length) { 
        watchlist.unshift(movieTranslation) 
    }
    localStorage.setItem('watchlist', JSON.stringify(watchlist))

    return !watchlist ? [] : Array.from(new Set(watchlist));
}
export const removeFromWatchlistLocalStorage = async movieTranslation => {
    let watchlist = await getWatchlistFromLocalstorage()
    watchlist = watchlist.filter(item => item.id !== movieTranslation.id)
    localStorage.setItem('watchlist', JSON.stringify(watchlist))

    return !watchlist ? [] : Array.from( new Set(watchlist) );
}

export const getGuestId = async () => {
    let guestId = await localStorage.getItem('abTestingId');
    if (!guestId) {
        guestId = generateId(10)
        localStorage.setItem('abTestingId', guestId)
    }

    return guestId;
}

export const getSessionId = async () => {
    let sessionId = await localStorage.getItem('abTestingId2');
    let lastAsked = await localStorage.getItem('abTestingId2_last');
    if (lastAsked && sessionId) {
        const minutsSinceSaved = (new Date().getTime() - new Date(localStorage.getItem("abTestingId2_last")).getTime())/1000/60
        if (minutsSinceSaved < 60*4) {
            localStorage.setItem('abTestingId2_last', new Date())
            return sessionId;
        }
    } 
    sessionId = generateId(10)
    localStorage.setItem('abTestingId2', sessionId)
    localStorage.setItem('abTestingId2_last', new Date())

    // Seize the opportunity to send an external user id to onesignal
    oneSignalSendExternalUserId()

    return sessionId;
}

export const homePageState = async (slug, state = null, language) => {
    let lastSaved = localStorage.getItem("homepageSavedDate")
    const lastLanguage = localStorage.getItem("homepageSavedLanguage")

    if (lastLanguage !== language) {
        lastSaved = null
    }

    if (state) {
        console.log("Saving state")
        setHomepageState(slug, state, lastSaved)
    }
    else {
        console.log("Getting state")
        return await getHomepageState(slug, lastSaved)
    }
}

const getHomepageState = async (slug, lastSaved) => {
    if (lastSaved) {
        const milisecondsSinceSaved = new Date().getTime() - new Date(localStorage.getItem("homepageSavedDate")).getTime()
        const minuts = milisecondsSinceSaved/1000/60;
        const savedSlug = localStorage.getItem("homepageSavedSlug")

        const savedState = await JSON.parse(localStorage.getItem('homepageState'))
        if (minuts <= 120 && savedState.rows !== null && slug === savedSlug) return savedState
    }

    localStorage.removeItem('homepageSavedSlug')
    localStorage.removeItem('homepageState')
    localStorage.removeItem('homepageSavedDate')
    return null
}

const setHomepageState = (slug, state, lastSaved) => {
    // console.log(lastSaved)
    if (!lastSaved) {
        localStorage.setItem('homepageState', JSON.stringify(state))
        localStorage.setItem('homepageSavedDate', new Date())
        localStorage.setItem('homepageSavedSlug', slug)
    } else {
        localStorage.setItem('homepageState', JSON.stringify(state))
    }
}

export const getI18nextLanguage = async () => localStorage.getItem('gatsby-i18next-language')
export const setI18nextLanguage = async lang => localStorage.setItem('gatsby-i18next-language', lang)

export const getI18nextLanguageFromPath = async () => {
    if (typeof window === "undefined") return
    let pathLanguage = window.location.pathname.split("/")[1]
    if (!["es","en","lat"].includes(pathLanguage)) { 
        pathLanguage = "fr" 
    }
    return pathLanguage
}

export const getI18nURLPrefix = async () => {
    const language = await getI18nextLanguage()
    const prefix = ["en","es","lat"].includes(language) ? `/${language}/`  : `/`
    return prefix
}