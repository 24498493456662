module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Cinetimes","short_name":"Cinetimes","start_url":"/","background_color":"#346bc2","theme_color":"#2557B7","display":"standalone","icon":"src/images/logo.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"e9fe534d0166db6b7e33718d81677f6e"},
    },{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"redirect":false,"generateDefaultLanguagePage":false,"localeJsonSourceName":"locale","languages":["fr","es","en","lat"],"defaultLanguage":"fr","siteUrl":"https://cinetimes.org","i18nextOptions":{"interpolation":{"escapeValue":false},"keySeparator":false,"nsSeparator":false},"pages":[{"matchPath":"/:lang?/video/:uid/","getLanguageFromPath":true,"excludeLanguages":["es","en","lat"]},{"matchPath":"/:lang?/blog/:uid/","getLanguageFromPath":true,"excludeLanguages":["es","en","lat"]},{"matchPath":"/:lang?/help/:uid","getLanguageFromPath":true,"excludeLanguages":["es","en","lat"]},{"matchPath":"/:lang?/collections/:uid","getLanguageFromPath":true,"excludeLanguages":["es","en","lat"]},{"matchPath":"/:lang?/list/:uid","getLanguageFromPath":true}]},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":590,"linkImagesToOriginal":true,"showCaptions":false,"markdownCaptions":false,"sizeByPixelDensity":false,"backgroundColor":"white","quality":50,"withWebp":false,"tracedSVG":false,"loading":"lazy","decoding":"async","disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../node_modules/gatsby-remark-autolink-headers/gatsby-browser.js'),
      options: {"plugins":[],"offsetY":0,"className":"anchor"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-78677033-1","optimizeId":"GTM-PPDHDBL","head":true,"anonymize":false,"respectDNT":false,"exclude":[],"pageTransitionDelay":0,"enableWebVitalsTracking":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-MQSLSB6","includeInDevelopment":false,"defaultDataLayer":{"type":"object","value":{"platform":"gatsby"}},"routeChangeEventName":"gatsby-route-change","enableWebVitalsTracking":true,"selfHostedOrigin":"https://www.googletagmanager.com"},
    },{
      plugin: require('../node_modules/gatsby-plugin-facebook-pixel/gatsby-browser.js'),
      options: {"plugins":[],"pixelId":"958534571744022"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
