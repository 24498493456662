import { navigate } from "gatsby"
import { toast } from "react-toastify"
import { getGuestId, getI18nextLanguage, getSessionId } from "./utils/localStorage"
import { sleep } from "./utils"

export const url = process.env.GATSBY_API_LIVE_URL
export const lang = 'fr'

export const getToken = async () => {
  if (typeof window === "undefined") return null
  return localStorage.getItem('userToken')
}
export const getUserId = async () => {
  if (typeof window === "undefined") return null
  return localStorage.getItem('userId')
}

export const adminIds = [1162, 1161]

export const fetchHomePage = async () => {
  const response = await fetch(`${url}/${lang}/movie`, {
    // headers: {'content-type': 'application/x-www-form-urlencoded'},
    method: 'GET',
  })
  if (response.ok) {
    const result = await response.json()
    return result
  }

  const errMessage = await response.text()
  return errMessage
}

export const signupRequest = async (signupData) => {
  const response = await fetch(`${url}/rest-auth/registration/`, {
    headers: {'content-type': 'application/json'},
    method: 'POST',
    body: JSON.stringify(signupData),
  })

  if (response.ok) {
    const result = await response.json()
    return result
  }

  const errMessage = await response.json()
  // Jsonify and parse later because error.messages will apply .toString()
  throw new Error(JSON.stringify(errMessage)) 
}

export const loginRequest = async (loginData) => {
  const response = await fetch(`${url}/rest-auth/login/`, {
    headers: {'content-type': 'application/json'},
    method: 'POST',
    body: JSON.stringify(loginData),
  })

  if (response.ok) {
    const result = await response.json()
    return result
  }

  const errMessage = await response.json()
  // Jsonify and parse later because error.messages will apply .toString()
  throw new Error(JSON.stringify(errMessage)) 
}

export const logoutRequest = async () => {
  const response = await fetch(`${url}/rest-auth/logout/`, {
    headers: {'content-type': 'application/json'},
    method: 'POST',
  })

  if (response.ok) {
    const result = await response.json()
    return result
  }

  const errMessage = await response.json()
  return errMessage
}

export const rateItem = async (rating, token, method) => {
  let isRatingToReplace = method === 'PUT' ? `/${rating.pk}/` : ''
  const response = await fetch(`${url}/rating${isRatingToReplace}`, {
    headers: {
      'content-type': 'application/json',
      'Authorization': `Token ${token}`,
    },
    method: method,
    body: JSON.stringify(rating),
  })

  if (response.ok) {
    const result = await response.json()
    return result
  }

  const errMessage = await response.json()
  return errMessage
}

export const getUser = async (token) => {
  console.log(token)
  const response = await fetch(`${url}/rest-auth/user/`, {
    headers: {
      'content-type': 'application/json',
      'Authorization': `Token ${token}`,
    },
    method: 'GET',
  })
  console.log(response)
  if (response.ok) {
    const result = await response.json()
    return result
  }

  const errMessage = await response.json()
  return errMessage
}

export const fetchRatings = async (token) => {
  const response = await fetch(`${url}/rating`, {
    headers: {
      'content-type': 'application/json',
      'Authorization': `Token ${token}`,
    },
    method: 'GET',
  })

  if (response.ok) {
    const result = await response.json()
    return result
  }

  const errMessage = await response.json()
  return errMessage
}

export const confirmEmail = async (key) => {
  const response = await fetch(`${url}/registration/verify-email/`, {
    headers: {
      'content-type': 'application/json',
    },
    method: 'POST',
    body: JSON.stringify(key)
  })

  if (response.ok) {
    const messages = await response.json()
    return messages
  }

  const errMessages = await response.json()
  throw new Error(JSON.stringify(errMessages))
}

export const createMovieRequest = async (movieData, token) => {
  const response = await fetch(`${url}/fr/movie`, {
    headers: {
      'content-type': 'application/json',
      'Authorization': `Token ${token}`,
    },
    method: 'POST',
    body: JSON.stringify(movieData),
  })

  if (response.ok) {
    const result = await response.json()
    return result
  }

  const errMessage = await response.json()
  // Jsonify and parse later because error.messages will apply .toString()
  throw new Error(JSON.stringify(errMessage)) 
}

// image = {size: cover|poster, image:dataURL, movie: pk}
export const uploadImage = async (image, token) => {

  const img = new FormData()

  img.append('image', image.image)
  img.append('size', image.size)
  img.append('movie', image.movie)

  const response = await fetch(`${url}/image`, {
    headers: {
      'Authorization': `Token ${token}`,
    },
    method: 'POST',
    body: img,
  })

  if (response.ok) {
    const result = await response.json()
    return result
  }

  const errMessage = await response.json()
  // Jsonify and parse later because error.messages will apply .toString()
  throw new Error(JSON.stringify(errMessage)) 
}

export const uploadVideo = async (videoData, token) => {
  const response = await fetch(`${url}/video`, {
    headers: {
      'content-type': 'application/json',
      'Authorization': `Token ${token}`,
    },
    method: 'POST',
    body: JSON.stringify(videoData),
  })

  if (response.ok) {
    const result = await response.json()
    return result
  }

  const errMessage = await response.json()
  // Jsonify and parse later because error.messages will apply .toString()
  throw new Error(JSON.stringify(errMessage)) 
}

export const deleteVideo = async (videoId, token) => {
  const response = await fetch(`${url}/video/${videoId}`, {
    headers: {
      'content-type': 'application/json',
      'Authorization': `Token ${token}`,
    },
    method: 'DELETE',
  })

  if (response.ok) {
    toast.success(`Vidéo supprimée (rechargez la page)`)
    return
  }
  toast.error(`Impossible de supprimer la vidéo. Rechargez la page`)
}

export const deleteImage = async (imageId, token) => {
  const response = await fetch(`${url}/image/${imageId}`, {
    headers: {
      'content-type': 'application/json',
      'Authorization': `Token ${token}`,
    },
    method: 'DELETE',
  })

  if (response.ok) {
    toast.success(`Image supprimée (rechargez la page)`)
    return
  }
  toast.error(`Impossible de supprimer l'image. Rechargez la page`)
}

export const deleteWatchOn = async (watchOnId, token) => {
  const response = await fetch(`${url}/watchon/${watchOnId}`, {
      headers: {
        'content-type': 'application/json',
        'Authorization': `Token ${token}`,
      },
      method: 'DELETE',
    })
  
    if (response.ok) {
      toast.success(`Service de streaming supprimé`)
      return
    }
    toast.error(`Erreur lors de la suppression. Rechargez la page`)
}

export const addWatchOn = async (watchOnData, token) => {
  const response = await fetch(`${url}/watchon`, {
    headers: {
      'content-type': 'application/json',
      'Authorization': `Token ${token}`,
    },
    method: 'POST',
    body: JSON.stringify(watchOnData),
  })

  if (response.ok) {
    toast.success(`Service de streaming ajouté`)
    const result = await response.json()
    return result
  }

  const errMessage = await response.json()
  // Jsonify and parse later because error.messages will apply .toString()
  throw new Error(JSON.stringify(errMessage))       
}


export const getMovieBySlug = async (slug, token = null) => {
  const response = await fetch(`${url}/${lang}/edit/t/${slug}/`, {
    headers: {
      'content-type': 'application/json',
      'Authorization': `Token ${token}`,
    },
    method: 'GET',
  })
  console.log(response)
  if (response.ok) {
    const result = await response.json()
    return result
  }

  const errMessage = await response.json()
  return errMessage
}

export const editMovieTranslation = async (movieData, slug, token = null) => {
  const response = await fetch(`${url}/${lang}/edit/t/${slug}/`, {
    headers: {
      'content-type': 'application/json',
      'Authorization': `Token ${token}`,
    },
    method: 'PUT',
    body: JSON.stringify(movieData),
  })

  if (response.ok) {
    const result = await response.json()
    return result
  }

  const errMessage = await response.json()
  // Jsonify and parse later because error.messages will apply .toString()
  throw new Error(JSON.stringify(errMessage)) 
}

export const editMovie = async (movieData, token) => {
  const id = movieData.id
  delete movieData.id
  const response = await fetch(`${url}/${lang}/edit/m/${id}/`, {
    headers: {
      'content-type': 'application/json',
      'Authorization': `Token ${token}`,
    },
    method: 'PUT',
    body: JSON.stringify(movieData),
  })

  if (response.ok) {
    const result = await response.json()
    return result
  }

  const errMessage = await response.json()
  // Jsonify and parse later because error.messages will apply .toString()
  throw new Error(JSON.stringify(errMessage)) 
}

export const fetchUserRating = async (id, token) => {
  const response = await fetch(`${url}/${lang}/movie/${id}/`, {
    method: 'GET',
    headers: {
      'content-type': 'application/json',
      'Authorization': `Token ${token}`,
    },
  })
  if (response.ok) {
    const result = await response.json()
    return result
  }

  const errMessage = await response.text()
  return errMessage
}

export const sendEmailOptout = async (username, token, regularity) => {
  const response = await fetch(`${url}/emails/email_optout/${username}/${token}/${regularity}/`, {
    method: 'GET',
    headers: {
      'content-type': 'application/json',
    },
  })
  if (response.ok) {
    const result = await response.json()
    return result
  }

  const err = await response.json()
  // Jsonify and parse later because error.messages will apply .toString()
  throw new Error(err.message) 
}

export const fetchRecommendedMovies = async url => {
  const token = await getToken()

  const response = await fetch(url, {
    method: 'GET',
    headers: {
      'content-type': 'application/json',
      'Authorization': `Token ${token}`,
    },
  })

  if (response.ok) {
    const result = await response.json()
    return result
  }

  const err = await response.text()
  throw new Error(err.message) 
}

export const fetchMovieTranslation = async (slug, lang="fr") => {
  const token = await getToken()

  const headers = {
    'content-type': 'application/json',
  }

  if (token){
    headers['Authorization'] = `Token ${token}`
  }

  const response = await fetch(`${url}/build/${lang}/movie/${slug}/`, {
    headers,
    method: 'GET',
    'Authorization': `Token ${token}`,
  })


  if (response.ok) {
    const result = await response.json()
    const movie = {
      id: result.movie.id,
      originalTitle: result.movie.original_title,
      originalLanguage: result.movie.original_language,
      originalCountry: result.movie.original_country,
      budget: result.movie.budget,
      boxOffice: result.movie.box_office,
      runtime: result.movie.runtime,
      release: result.movie.release,
      genres: result.movie.genres,
      tmdbId: result.movie.tmdb_id,
      imdbId: result.movie.imdb_id,
      liked: result.liked,
      disliked: result.disliked,
    }
    const movieTranslation = {
      id: result.id,
      analytics: result.analytics,
      recommendations: result.recommendations,
      ratings: result.ratings,
      smartRecommendations: result.smart_recommendations,
      poster: result.poster,
      posterThumb: result.poster_thumb,
      posterThumbX450: result.poster_thumb_X450,
      videos: result.videos,
      movies: result.movies,
      isStreaming: result.is_streaming,
      trailers: result.trailers,
      avgRating: result.avg_rating,
      totalRatings: result.total_ratings,
      slug: result.slug,
      title: result.title,
      language: result.language,
      synopsis: result.synopsis,
      created: result.created,
      currUserRating: result.user_rating,
      watchOn: result.watch_on,
      inWatchlist: result.in_watchlist,
      tags: result.tags,
      related_by_tags: result.related_by_tags,
      products_related_by_tags: result.products_related_by_tags,
      related_also_watched: result.related_also_watched,
    }
    return {movie, movieTranslation}
  }
  else if (response.status === 404) {
    const language = await getI18nextLanguage()
    const newSlug = await geti18nEquivalentSlug(slug)
    if (newSlug && newSlug !== "Slug not found" ) {
      if (language === "fr"){
        navigate(`/t/${newSlug}`)
      } else {
        navigate(`/${language}/t/${newSlug}`)
      }
    } else {
      navigate("/")
    }
  }
  console.log(response)
}

export const sendEmailWithResetPasswordLink = async email => {
  const response = await fetch(`${url}/password_reset/`, {
    headers: {'content-type': 'application/json'},
    method: 'POST',
    body: JSON.stringify({ email: email })
  })
  if (response.ok) {
    const result = await response.json()
    return result
  }
  const err = await response.json()
  throw new Error(err.email) 
}

// export const verifyResetPasswordToken = async token => {
//   // URL not working
//   const response = fetch(`${url}/password_reset/validate_token/`, {
//     headers: {'content-type': 'application/json'},
//     method: 'POST',
//     body: JSON.stringify({ token })
//   })

//   if (response.ok) return true
//   return false
// }

export const changeUserPassword = async (password, token) => {
  const response = await fetch(`${url}/password_reset/confirm/`, {
    headers: {'content-type': 'application/json'},
    method: 'POST',
    body: JSON.stringify({ password, token })
  })
  if (response.ok) {
    const result = await response.json()
    return result
  }
  const err = await response.json()
  if (err.password) {
    err.password.forEach(i => {
      toast.error(i)
    })
    return 'error'
  } else if (err.status === 'notfound') {
    throw new Error("Token expiré") 
  }
}

export const fetchRelated = async (translationId) => {
  // const response = await fetch(`${url}/${lang}/t/${translationId}/related`, {method: 'GET',})
  const response = await fetch(`${url}/${lang}/t/${translationId}/relatedtmdb`, {method: 'GET',})
  if (response.ok) {
      const related = await response.json()
      return related
  }

  return false
}

const processSuggestion = option => ({
  id: option._source.id,
  title: option._source.details[0].title,
  slug: option._source.details[0].slug,
  poster: option._source.details[0].poster,
})

export const getSuggestions = async input => {
  try {
    const response = await fetch(`${url}/search/suggest/?title_suggest__completion=${input}`)
    const result = await response.json()
    
    if (!result.title_suggest__completion[0].length) return undefined

    return {
      data: result.title_suggest__completion[0].options.map(processSuggestion), 
    }
  } catch (err) {
    console.log(err)
  }
}

export const getSuggestionsApi = async input => {
  try {
    const response = await fetch(`${url}/fr/suggest/${input}`)
    const result = await response.json()
    
    if (!result.length) return undefined

    return {
      data: result, 
    }
  } catch (err) {
    console.log(err)
  }
}

export const fetchVideo = async videoId => {
  const token = await getToken()
  
  const headers = {'content-type': 'application/json'}
  if (token !== null) {
      headers['Authorization'] = `Token ${token}`
  }

  let language = await getI18nextLanguage()
  if (!language) {
    language = "fr"
  }

  const response = await fetch(`${url}/videos/${language}/${videoId}`, {
    headers: headers,
    method: 'GET',
  })
  if (response.ok) {
    const result = await response.json()
    return result
  }

  const errMessage = await response.text()
  throw new Error(errMessage)
}

export const fetchRelatedVideos = async videoId => {
  let language = await getI18nextLanguage()
  if (!language) {
    language = "fr"
  }
  const response = await fetch(`${url}/videos/${language}/${videoId}/related`, {
    // headers: {'content-type': 'application/x-www-form-urlencoded'},
    method: 'GET',
  })
  if (response.ok) {
    const result = await response.json()
    return result
  }

  const errMessage = await response.text()
  throw new Error(errMessage)
}

export const fetchTranslationRelatedVideos = async translationId => {
  const response = await fetch(`${url}/videos/translation/${translationId}/related`, {
    // headers: {'content-type': 'application/x-www-form-urlencoded'},
    method: 'GET',
  })
  if (response.ok) {
    const result = await response.json()
    return result
  }

  const errMessage = await response.text()
  throw new Error(errMessage)
}

export const fetchVideoSimilarByTags = async videoId => {
  const response = await fetch(`${url}/videos/${videoId}/similar_tags`, {
    // headers: {'content-type': 'application/x-www-form-urlencoded'},
    method: 'GET',
  })
  if (response.ok) {
    const result = await response.json()
    return result
  }

  const errMessage = await response.text()
  throw new Error(errMessage)
}

export const logEventBeacon = async (event, slug, itemName, value, itemId) => {
  let guestId = null
  const token = await getToken()
  const sessionId = await getSessionId()
  const headers = {'content-type': 'application/json'}
  if (token !== null) {
      const userId = await getUserId()
      if (adminIds.includes(parseInt(userId))) return
      localStorage.removeItem("abTestingId")
      headers['Authorization'] = `Token ${token}`
  } else {
    guestId = await getGuestId()
  }
  const body = {
    event: event, 
    slug: slug, 
    itemName: itemName,
    value: value,
    itemId: itemId,
    guestId:guestId,
    sessionId:sessionId
  }
  navigator.sendBeacon(`${url}/analytics/event`, JSON.stringify(body))
  const response = await fetch(`${url}/analytics/event`, {
    headers: headers,
    method: 'POST',
    body: JSON.stringify(body)
  }) 
}

export const logEvent = async (event, slug, itemName, value, itemId) => {
  let guestId = null
  const token = await getToken()
  const sessionId = await getSessionId()
  const headers = {'content-type': 'application/json'}
  if (token !== null) {
      const userId = await getUserId()
      if (adminIds.includes(parseInt(userId))) return
      localStorage.removeItem("abTestingId")
      headers['Authorization'] = `Token ${token}`
  } else {
    guestId = await getGuestId()
  }
  
  const body = {
    event: event, 
    slug: slug, 
    itemName: itemName,
    value: value,
    itemId: itemId,
    guestId:guestId,
    sessionId:sessionId
  }

  const response = await fetch(`${url}/analytics/event`, {
      headers: headers,
      method: 'POST',
      body: JSON.stringify(body)
    }) 

  if (response.ok) {
    const result = await response.json()
    return result
  }

  const errMessage = await response.text()
  throw new Error(errMessage)
}

export const PostSigninOperations = async () => {

  let guestId = await getGuestId()
  const token = await getToken()

  const headers = {'content-type': 'application/json'}
  headers['Authorization'] = `Token ${token}`

  const userId = await getUserId()
  if (adminIds.includes(parseInt(userId))) return

  const response = await fetch(`${url}/user/post_signin/?guest_id=${guestId}`, {
      headers: headers,
      method: 'GET',
    }) 

  if (response.ok) {
    const result = await response.json()
    return result
  }

  const errMessage = await response.text()
  throw new Error(errMessage)
}

export const getTagMovieTranslationlist = async (slug, page = 1) => {
  const token = await getToken()
  const headers = {'content-type': 'application/json'}
  if (token !== null) {
      headers['Authorization'] = `Token ${token}`
  }

  let lang = await getI18nextLanguage()
  const langParam = !lang ? '' :  `&language=${lang}`

  const response = await fetch(`${url}/tag/${slug}/?page=${page}${langParam}`, {
    headers: headers,
    method: 'GET',
  })

  if (response.ok) {
    const result = await response.json()
    return result
  }

  const errMessage = await response.json()
  throw new Error(JSON.stringify(errMessage))  
}

export const getTagRelatedTags = async (slug) => {
  const headers = {'content-type': 'application/json'}

  const response = await fetch(`${url}/tag/${slug}/related/`, {
    headers: headers,
    method: 'GET',
  })

  if (response.ok) {
    const result = await response.json()
    return result
  }

  const errMessage = await response.json()
  throw new Error(JSON.stringify(errMessage))  
}

export const geti18nEquivalentSlug = async slug => {
  const headers = {'content-type': 'application/json'}
  const language = await getI18nextLanguage()

  const response = await fetch(`${url}/videos/${language}/${slug}/equivalent`, {
    headers: headers,
    method: 'GET',
  })

  if (response.ok) {
    const result = await response.json()
    return result.results
  }

  const errMessage = await response.json()
  throw new Error(JSON.stringify(errMessage))  
}

// export const logEvent = async (event, item, itemId) => {
//   const token = await getToken()
  
  
//   const headers = {'content-type': 'application/json'}
//   if (token !== null) {
//       headers['Authorization'] = `Token ${token}`
//   }

//   const response = await fetch(`${url}/videos/${videoId}`, {
//     headers: headers,
//     method: 'GET',
//   })
//   if (response.ok) {
//     const result = await response.json()
//     return result
//   }

//   const errMessage = await response.text()
//   throw new Error(errMessage)
// }