import wrapWithProvider from "./wrap-with-provider"
import './src/global.css'
import './src/templates/movie.css'
export const wrapRootElement = wrapWithProvider

export const onServiceWorkerUpdateReady = () => {
    const answer = window.confirm(
      `Cette application a été mise à jour. ` +
        `Recharger la page pour afficher la dernière version ?`
    )
    if (answer === true) {
      window.location.reload()
    }
  }