import {GENRES, GENRES_TABLE_FR} from './const'
import { getUserId } from './api';
import { getGuestId } from './utils/localStorage';

export const shuffle = array => {
  let counter = array.length;

  // While there are elements in the array
  while (counter > 0) {
      // Pick a random index
      let index = Math.floor(Math.random() * counter);

      // Decrease counter by 1
      counter--;

      // And swap the last element with it
      let temp = array[counter];
      array[counter] = array[index];
      array[index] = temp;
  }
  return array;
}

export const getGenresFromIds = (genreIdTable, length = null) => {
  if (!genreIdTable === false && genreIdTable.length !== 0) {
    if (length && genreIdTable.length > 3) genreIdTable = genreIdTable.slice(0, length)
    const genreNames = genreIdTable.map(i => `${GENRES_TABLE_FR[i]}, `)
    genreNames[genreNames.length-1] = genreNames[genreNames.length-1].slice(0, -2)
    return genreNames
  }
  return undefined
}

export const parseUrlParams = (url = window.location.href) => {
  const question = url.indexOf('?');
  let hash = url.indexOf('#');
  if (hash === -1 && question === -1) return {};
  if (hash === -1) hash = url.length;
  const query = question === -1 || hash === question + 1
    ? url.substring(hash)
    : url.substring(question + 1, hash);
  const result = {};
  query.split('&').forEach((part) => {
    if (!part) return;
    part = part.split('+').join(' '); // replace every + with space, regexp-free version
    const eq = part.indexOf('=');
    let key = eq > -1 ? part.substr(0, eq) : part;
    const val = eq > -1 ? decodeURIComponent(part.substr(eq + 1)) : '';
    const from = key.indexOf('[');
    if (from === -1) {
      result[decodeURIComponent(key)] = val;
    } else {
      const to = key.indexOf(']', from);
      const index = decodeURIComponent(key.substring(from + 1, to));
      key = decodeURIComponent(key.substring(0, from));
      if (!result[key]) result[key] = [];
      if (!index) result[key].push(val);
      else result[key][index] = val;
    }
  });
  return result;
};

export function is_touch_device() {
    
  var prefixes = ' -webkit- -moz- -o- -ms- '.split(' ');
  
  var mq = function (query) {
      return window.matchMedia(query).matches;
  }

  if (('ontouchstart' in window) || window.DocumentTouch) {
      return true;
  }

  // include the 'heartz' as a way to have a non matching MQ to help terminate the join
  // https://git.io/vznFH
  var query = ['(', prefixes.join('touch-enabled),('), 'heartz', ')'].join('');
  return mq(query);
}

export const generateId = length => {
  let result = [];
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ-abcdefghijklmnopqrstuvwxyz_0123456789';
  const charactersLength = characters.length;
  for ( let i = 0; i < length; i++ ) {
     result.push(characters.charAt(Math.floor(Math.random() * charactersLength)))
  }
  return result.toString().replace(/,/g,'');
}

export function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}



export const isUrlCorrect = url => {
  if (url.includes('youtube.com')) return 'youtube'
  if (url.includes('youtu.be')) return 'youtube'
  if (url.includes('archive.org')) return 'archive'
  if (url.includes('dailymotion.com')) return 'dailymotion'
  if (url.includes('dai.ly')) return 'dailymotion'
  if (url.includes('vimeo.com')) return 'vimeo'
  return false
}


const parseVideoYoutube = url => {
  let result = ''
  if (url.includes('youtube.com')) {
    const regex = /youtube\.com\/watch\?v=[!-z][^&]+/g;
    const found = url.match(regex);
    result = "https://www." + found[0]
  }
  else if (url.includes('youtu.be')) {
    const regex = /youtu\.be\/[!-z][^&]+/g;
    const found = url.match(regex);
    const videoId = found[0].split("/")[1]
    result = "https://www.youtube.com/watch?v=" + videoId
  }
  return result
}
const parseVideoDailymotion = url => {
  if (url.includes('dailymotion.com/embed/video')) {
    return url
  }
  else if (url.includes('dailymotion.com/video') || url.includes('dai.ly')) {
    const videoId = url.split("/")
    return "https://www.dailymotion.com/embed/video/" + videoId[videoId.length - 1]
  }
  return false
}
const parseVideoVimeo = url => {
  if (url.includes('player.vimeo.com')) {
    const regex = /video\/[!-z][^&]+/g;
    const found = url.match(regex);
    const videoId = found[0].split("/")[1]
    return "https://vimeo.com/" + videoId
  }
  else if (url.includes('vimeo.com')) {
    return url
  }
  return false
}
const parseVideoArchive = url => {
  if (url.includes('archive.org/embed')) {
    return url
  }
  else if (url.includes('archive.org/details')) {
    const videoId = url.split("/")
    return "https://archive.org/embed/" + videoId[videoId.length - 1]
  }
  return false
}

export const parseVideoUrl = supposedUrl => {
  try {
    const whoIs = isUrlCorrect(supposedUrl)
    if (!whoIs) return
    let parsedUrl = ''
    if (whoIs === "youtube") parsedUrl = parseVideoYoutube(supposedUrl)
    if (whoIs === "dailymotion") parsedUrl = parseVideoDailymotion(supposedUrl)
    if (whoIs === "vimeo") parsedUrl = parseVideoVimeo(supposedUrl)
    if (whoIs === "archive") parsedUrl = parseVideoArchive(supposedUrl)
  
    return parsedUrl
  } catch (err) {
    return false
  }
}

export function titleFromSlug(slug) {
  if (!slug){
    return ''
  }
  return slug.replace(/-/g, " ").replace(/\b[a-z]/g, function() {
    return arguments[0].toUpperCase();
  });
}

export function slugify(slug) {
  if (!slug){
    return ''
  }
  return slug.toLowerCase().replace(/ /g,'-').replace(/[^\w-]+/g,'')
}

// parseVideoUrl("https://www.youtube.com/watch?v=ZijqVV1NqYQ")
// parseVideoUrl("https://www.youtube.com/watch?v=SlPhMPnQ58k&list=PL4o29bINVT4EG_y-k5jGoOu3-Am8Nvi10")
// parseVideoUrl("https://youtu.be/ZijqVV1NqYQ")
// parseVideoUrl("https://archive.org/details/vietnam-travel-guide-hanoi-ha-long-bay-ho-chi-minh_202012")
// parseVideoUrl("https://archive.org/embed/vietnam-travel-guide-hanoi-ha-long-bay-ho-chi-minh_202012")
// parseVideoUrl("https://www.dailymotion.com/video/x7v6gyb")
// parseVideoUrl("https://www.dailymotion.com/embed/video/x7v6gyb")
// parseVideoUrl("https://dai.ly/x7v6gyb")
// parseVideoUrl("https://vimeo.com/41586793")
// parseVideoUrl("https://player.vimeo.com/video/41586793?title=0&byline=0&portrait=0")



// if 'youtube.com' or 'youtu.be' in url:
// if 'youtube.com' in url:
//     string = re.search("youtube\.com\/watch\?v=[!-z][^&]+", url)
//     if not string:
//         return None
//     parsed = string.group(0)
// else:
//     string = re.search("youtu\.be\/[!-z]+", url)
//     if not string:
//         return None
//     parsed = "youtube.com/watch?v=" + string.group(0).split("/")[1]
// return parsed


export const oneSignalSendKeepWatching = (title, slug, poster) => {
  if ( typeof window !== "undefined"){
    window.OneSignal.push(function() {
      window.OneSignal.isPushNotificationsEnabled(function(isEnabled) {
        if (isEnabled) {        
          let timestamp = Math.floor(Date.now() / 1000);
          window.OneSignal.sendTags({
            "last_pageview_update": timestamp,
            "last_pageview_title": title,
            "last_pageview_slug": slug,
            "last_pageview_image_url": poster,
        })
        oneSignalSendExternalUserId()
        }
      });
    });
  }
}

export const oneSignalSendExternalUserId = async () => {
  if ( typeof window === "undefined") return

  let externalUserId = await getUserId()

  if (externalUserId == null) {
    externalUserId = await getGuestId()
  }

  window.OneSignal.push(function() {
    window.OneSignal.setExternalUserId(externalUserId);
  });
  
}