import {
  createMovieRequest,
  getUser,
  loginRequest,
  logoutRequest,
  rateItem,
  signupRequest,
  uploadImage,
  fetchRecommendedMovies,
  PostSigninOperations,
} from '../api'

import {navigate} from 'gatsby'
import {toast} from 'react-toastify'
import { syncWatchlist } from '../apis/watchlist'

export const CREATE_MOVIE_REQUEST_FULFILLED = 'CREATE_MOVIE_REQUEST_FULFILLED'
export const CREATE_MOVIE_REQUEST_REJECTED = 'CREATE_MOVIE_REQUEST_REJECTED'

export const LOG_IN_SENT = 'LOG_IN_SENT'
export const LOG_IN_FULFILLED = 'LOG_IN_FULFILLED'
export const LOG_IN_REJECTED = 'LOG_IN_REJECTED'
export const LOG_OUT_FULFILLED = 'LOG_OUT_FULFILLED'
export const LOG_OUT_REJECTED = 'LOG_OUT_REJECTED'

export const SIGN_UP_SENT = 'SIGN_UP_SENT'
export const SIGN_UP_FULFILLED = 'SIGN_UP_FULFILLED'
export const SIGN_UP_REJECTED = 'SIGN_UP_REJECTED'

export const FETCHING_USER_REQUEST_FULFILLED = 'FETCHING_USER_REQUEST_FULFILLED'
export const FETCHING_USER_REQUEST_REJECTED = 'FETCHING_USER_REQUEST_REJECTED'

export const RECOMMENDED_MOVIES_REQUEST_FULFILLED = 'RECOMMENDED_MOVIES_REQUEST_FULFILLED'
export const RECOMMENDED_MOVIES_REQUEST_REJECTED = 'RECOMMENDED_MOVIES_REQUEST_REJECTED'

export const RESET_ERROR_MESSAGE = 'RESET_ERROR_MESSAGE'


export const login = (loginData) => async dispatch => {
  dispatch({type: LOG_IN_SENT})
  try {
    const result = await loginRequest(loginData)
    const token = result['key']
    dispatch({type: LOG_IN_FULFILLED, payload: token})
  } catch (err) {
    dispatch({type: LOG_IN_REJECTED, payload: JSON.parse(err.message) || 'Something went wrong'})
  }
}

export const signup = (signupData) => async dispatch => {
  dispatch({type: SIGN_UP_SENT})
  try {
    const result = await signupRequest(signupData)
    const token = result['key']
    dispatch({type: SIGN_UP_FULFILLED, payload: token})
  } catch (err) {
    dispatch({type: SIGN_UP_REJECTED, payload: JSON.parse(err.message) || 'Something went wrong'})
  }
}

export const logout = () => async dispatch => {
  try {
    logoutRequest()
    dispatch({type: LOG_OUT_FULFILLED})
    localStorage.removeItem('userToken')
    localStorage.removeItem('userId')
    localStorage.removeItem('userName')
    toast.info(`👋`, {position: "bottom-left"})
  } catch (err) {
    dispatch({type: LOG_OUT_REJECTED, payload: err.message || 'Something went wrong'})
    toast.error('❌')
  }
}

export const fetchUser = token => async dispatch => {
  try {
    const user = await getUser(token)
    dispatch({type: FETCHING_USER_REQUEST_FULFILLED, payload: user})
    PostSigninOperations()
    
    localStorage.setItem('userToken', token)
    localStorage.setItem('userName', user.username)
    localStorage.setItem('userId', user.pk)
    localStorage.removeItem('abTestingId')
    syncWatchlist()
    if (window.ReactNativeWebView) window.ReactNativeWebView.postMessage(JSON.stringify({token: token, user: user}))
  } catch (err) {
    dispatch({type: FETCHING_USER_REQUEST_REJECTED, error: err.message || 'Something went wrong'})
    toast.error('❌')
  }
}

export const createMovie = (movieData, imgData = null, token) => async dispatch => {
  try {
    const result = await createMovieRequest(movieData, token)
    dispatch({type: CREATE_MOVIE_REQUEST_FULFILLED, payload: result})
    toast.success(`Film ajouté! Il apparaîtra bientôt sur le site`)
    if (imgData !== null)
      imgData.forEach(async img => {
        img.movie = result.id
        await uploadImage(img, token)
      })
    await navigate(`/edit/${result.slug}`)
  } catch (err) {
    dispatch({type: CREATE_MOVIE_REQUEST_REJECTED, error: err.message || 'Something went wrong'})
    toast.error(err.message || 'Something went wrong')
  }
}

export const addImage = imgData => async dispatch => {
  try {
    const result = await uploadImage(imgData)
    // dispatch({type: CREATE_MOVIE_REQUEST_FULFILLED, payload: result})
    toast.success(`Image ajoutée !`)
  } catch (err) {
    // dispatch({type: CREATE_MOVIE_REQUEST_REJECTED, error: err.message || 'Something went wrong'})
    toast.error(err.message || 'Something went wrong')
  }
}

export const getMovieRecommendations = url => async dispatch => {
  try {
    const result = await fetchRecommendedMovies(url)
    dispatch({type: RECOMMENDED_MOVIES_REQUEST_FULFILLED, payload: result})
    toast.success(`Recommandations mises à jour`)
  } catch(err) {
    dispatch({type: RECOMMENDED_MOVIES_REQUEST_REJECTED, error: err.message})
    toast.error(err.message || 'Something went wrong')
  }
}


