export const CONTENT_TYPE_EPISODE = 1
export const CONTENT_TYPE_MOVIE_TRANSLATION = 5
export const CONTENT_TYPE_PERSON = 6
export const CONTENT_TYPE_SERIE = 7

export const GENRES = {
  'Action': 1,
  'Adventure': 2,
  'Animation': 3,
  'Biography': 4,
  'Comedy': 5,
  'Crime': 6,
  'Documentary': 7,
  'Drama': 8,
  'Family': 9,
  'Fantasy': 10,
  'Film-Noir': 11,
  'History': 12,
  'Horror': 13,
  'Music': 14,
  'Musical': 15,
  'Mystery': 16,
  'Romance': 17,
  'Sci-Fi': 18,
  'Short': 19,
  'Sport': 20,
  'Thriller': 21,
  'War': 22,
  'Western': 23,
}

export const GENRES_EN = {
  1: 'Action',
  2: 'Adventure',
  3: 'Animation',
  4: 'Biography',
  5: 'Comedy',
  6: 'Crime',
  7: 'Documentary',
  8: 'Drama',
  9: 'Family',
  10: 'Fantasy',
  11: 'Film-Noir',
  12: 'History',
  13: 'Horror',
  14: 'Music',
  15: 'Musical',
  16: 'Mystery',
  17: 'Romance',
  18: 'Sci-Fi',
  19: 'Short',
  20: 'Sport',
  21: 'Thriller',
  22: 'War',
  23: 'Western',
}

export const GENRES_FR = {
   1: "Action",
   2: "Aventure",
   3: "Animation",
   4: "Biographie",
   5: "Comédie",
   6: "Crime",
   7: "Documentaire",
   8: "Drame",
   9: "Famille",
   10: "Fantaisie",
   11: "Film-Noir",
   12: "Histoire",
   13: "Horreur",
   14: "Musique",
   15: "Musical",
   16: "Mystère",
   17: "Romance",
   18: "Sci-Fi",
   19: "Court-Métrage",
   20: "Sport",
   21: "Thriller",
   22: "Guerre",
   23: "Western",
}

export const GENRES_TABLE = [
  '',
  'Action',
  'Adventure',
  'Animation',
  'Biography',
  'Comedy',
  'Crime',
  'Documentary',
  'Drama',
  'Family',
  'Fantasy',
  'Film-Noir',
  'History',
  'Horror',
  'Music',
  'Musical',
  'Mystery',
  'Romance',
  'Sci-Fi',
  'Short',
  'Sport',
  'Thriller',
  'War',
  'Western',
]

export const GENRES_TABLE_FR = [
  '',
  'Action',
  'Aventure',
  'Animation',
  'Biographie',
  'Comédie',
  'Crime',
  'Documentaire',
  'Drame',
  'Famille',
  'Fantaisie',
  'Film-Noir',
  'Histoire',
  'Horreur',
  'Musique',
  'Musical',
  'Mystère',
  'Romance',
  'Sci-Fi',
  'Court-Métrage',
  'Sport',
  'Thriller',
  'Guerre',
  'Western',
]

export const GENRES_EN_TO_FR = {
    'Action': 'Action',
    'Adventure': 'Aventure',
    'Animation': 'Animation',
    'Biography': 'Biographie',
    'Comedy': 'Comédie',
    'Crime': 'Crime',
    'Documentary': 'Documentaire',
    'Drama': 'Drame',
    'Family': 'Famille',
    'Fantasy': 'Fantaisie',
    'Film-Noir': 'Film-Noir',
    'History': 'Histoire',
    'Horror': 'Horreur',
    'Music': 'Musique',
    'Musical': 'Musical',
    'Mystery': 'Mystère',
    'Romance': 'Romance',
    'Sci-Fi': 'Sci-Fi',
    'Short': 'Court-Métrage',
    'Sport': 'Sport',
    'Thriller': 'Thriller',
    'War': 'Guerre',
    'Western': 'Western',
}

export const COUNTRIES_EN = {
  "AF": "Afghanistan",
  "AL": "Albania",
  "DZ": "Algeria",
  "AS": "American Samoa",
  "AD": "Andorra",
  "AO": "Angola",
  "AI": "Anguilla",
  "AQ": "Antarctica",
  "AG": "Antigua and Barbuda",
  "AR": "Argentina",
  "AM": "Armenia",
  "AW": "Aruba",
  "AU": "Australia",
  "AT": "Austria",
  "AZ": "Azerbaijan",
  "BS": "Bahamas",
  "BH": "Bahrain",
  "BD": "Bangladesh",
  "BB": "Barbados",
  "BY": "Belarus",
  "BE": "Belgium",
  "BZ": "Belize",
  "BJ": "Benin",
  "BM": "Bermuda",
  "BT": "Bhutan",
  "BO": "Bolivia",
  "BA": "Bosnia and Herzegovina",
  "BW": "Botswana",
  "BV": "Bouvet Island",
  "BR": "Brazil",
  "IO": "British Indian Ocean Territory",
  "BN": "Brunei Darussalam",
  "BG": "Bulgaria",
  "BF": "Burkina Faso",
  "BI": "Burundi",
  "KH": "Cambodia",
  "CM": "Cameroon",
  "CA": "Canada",
  "CV": "Cape Verde",
  "KY": "Cayman Islands",
  "CF": "Central African Republic",
  "TD": "Chad",
  "CL": "Chile",
  "CN": "China",
  "CX": "Christmas Island",
  "CC": "Cocos (Keeling) Islands",
  "CO": "Colombia",
  "KM": "Comoros",
  "CG": "Congo",
  "CD": "Congo, the Democratic Republic of the",
  "CK": "Cook Islands",
  "CR": "Costa Rica",
  "CI": "Cote D'Ivoire",
  "HR": "Croatia",
  "CU": "Cuba",
  "CY": "Cyprus",
  "CZ": "Czech Republic",
  "DK": "Denmark",
  "DJ": "Djibouti",
  "DM": "Dominica",
  "DO": "Dominican Republic",
  "EC": "Ecuador",
  "EG": "Egypt",
  "SV": "El Salvador",
  "GQ": "Equatorial Guinea",
  "ER": "Eritrea",
  "EE": "Estonia",
  "ET": "Ethiopia",
  "FK": "Falkland Islands (Malvinas)",
  "FO": "Faroe Islands",
  "FJ": "Fiji",
  "FI": "Finland",
  "FR": "France",
  "GF": "French Guiana",
  "PF": "French Polynesia",
  "TF": "French Southern Territories",
  "GA": "Gabon",
  "GM": "Gambia",
  "GE": "Georgia",
  "DE": "Germany",
  "GH": "Ghana",
  "GI": "Gibraltar",
  "GR": "Greece",
  "GL": "Greenland",
  "GD": "Grenada",
  "GP": "Guadeloupe",
  "GU": "Guam",
  "GT": "Guatemala",
  "GN": "Guinea",
  "GW": "Guinea-Bissau",
  "GY": "Guyana",
  "HT": "Haiti",
  "HM": "Heard Island and Mcdonald Islands",
  "VA": "Holy See (Vatican City State)",
  "HN": "Honduras",
  "HK": "Hong Kong",
  "HU": "Hungary",
  "IS": "Iceland",
  "IN": "India",
  "ID": "Indonesia",
  "IR": "Iran, Islamic Republic of",
  "IQ": "Iraq",
  "IE": "Ireland",
  "IL": "Israel",
  "IT": "Italy",
  "JM": "Jamaica",
  "JP": "Japan",
  "JO": "Jordan",
  "KZ": "Kazakhstan",
  "KE": "Kenya",
  "KI": "Kiribati",
  "KP": "North Korea",
  "KR": "South Korea",
  "KW": "Kuwait",
  "KG": "Kyrgyzstan",
  "LA": "Lao People's Democratic Republic",
  "LV": "Latvia",
  "LB": "Lebanon",
  "LS": "Lesotho",
  "LR": "Liberia",
  "LY": "Libya",
  "LI": "Liechtenstein",
  "LT": "Lithuania",
  "LU": "Luxembourg",
  "MO": "Macao",
  "MK": "Macedonia, the Former Yugoslav Republic of",
  "MG": "Madagascar",
  "MW": "Malawi",
  "MY": "Malaysia",
  "MV": "Maldives",
  "ML": "Mali",
  "MT": "Malta",
  "MH": "Marshall Islands",
  "MQ": "Martinique",
  "MR": "Mauritania",
  "MU": "Mauritius",
  "YT": "Mayotte",
  "MX": "Mexico",
  "FM": "Micronesia, Federated States of",
  "MD": "Moldova, Republic of",
  "MC": "Monaco",
  "MN": "Mongolia",
  "MS": "Montserrat",
  "MA": "Morocco",
  "MZ": "Mozambique",
  "MM": "Myanmar",
  "NA": "Namibia",
  "NR": "Nauru",
  "NP": "Nepal",
  "NL": "Netherlands",
  "NC": "New Caledonia",
  "NZ": "New Zealand",
  "NI": "Nicaragua",
  "NE": "Niger",
  "NG": "Nigeria",
  "NU": "Niue",
  "NF": "Norfolk Island",
  "MP": "Northern Mariana Islands",
  "NO": "Norway",
  "OM": "Oman",
  "PK": "Pakistan",
  "PW": "Palau",
  "PS": "Palestinian Territory, Occupied",
  "PA": "Panama",
  "PG": "Papua New Guinea",
  "PY": "Paraguay",
  "PE": "Peru",
  "PH": "Philippines",
  "PN": "Pitcairn",
  "PL": "Poland",
  "PT": "Portugal",
  "PR": "Puerto Rico",
  "QA": "Qatar",
  "RE": "Reunion",
  "RO": "Romania",
  "RU": "Russian Federation",
  "RW": "Rwanda",
  "SH": "Saint Helena",
  "KN": "Saint Kitts and Nevis",
  "LC": "Saint Lucia",
  "PM": "Saint Pierre and Miquelon",
  "VC": "Saint Vincent and the Grenadines",
  "WS": "Samoa",
  "SM": "San Marino",
  "ST": "Sao Tome and Principe",
  "SA": "Saudi Arabia",
  "SN": "Senegal",
  "SC": "Seychelles",
  "SL": "Sierra Leone",
  "SG": "Singapore",
  "SK": "Slovakia",
  "SI": "Slovenia",
  "SB": "Solomon Islands",
  "SO": "Somalia",
  "ZA": "South Africa",
  "GS": "South Georgia and the South Sandwich Islands",
  "ES": "Spain",
  "LK": "Sri Lanka",
  "SD": "Sudan",
  "SR": "Suriname",
  "SJ": "Svalbard and Jan Mayen",
  "SZ": "Swaziland",
  "SE": "Sweden",
  "CH": "Switzerland",
  "SY": "Syrian Arab Republic",
  "TW": "Taiwan",
  "TJ": "Tajikistan",
  "TZ": "Tanzania, United Republic of",
  "TH": "Thailand",
  "TL": "Timor-Leste",
  "TG": "Togo",
  "TK": "Tokelau",
  "TO": "Tonga",
  "TT": "Trinidad and Tobago",
  "TN": "Tunisia",
  "TR": "Turkey",
  "TM": "Turkmenistan",
  "TC": "Turks and Caicos Islands",
  "TV": "Tuvalu",
  "UG": "Uganda",
  "UA": "Ukraine",
  "AE": "United Arab Emirates",
  "GB": "United Kingdom",
  "US": "United States of America",
  "UM": "United States Minor Outlying Islands",
  "UY": "Uruguay",
  "UZ": "Uzbekistan",
  "VU": "Vanuatu",
  "VE": "Venezuela",
  "VN": "Viet Nam",
  "VG": "Virgin Islands, British",
  "VI": "Virgin Islands, U.S.",
  "WF": "Wallis and Futuna",
  "EH": "Western Sahara",
  "YE": "Yemen",
  "ZM": "Zambia",
  "ZW": "Zimbabwe",
  "AX": "Åland Islands",
  "BQ": "Bonaire, Sint Eustatius and Saba",
  "CW": "Curaçao",
  "GG": "Guernsey",
  "IM": "Isle of Man",
  "JE": "Jersey",
  "ME": "Montenegro",
  "BL": "Saint Barthélemy",
  "MF": "Saint Martin (French part)",
  "RS": "Serbia",
  "SX": "Sint Maarten (Dutch part)",
  "SS": "South Sudan",
  "XK": "Kosovo"
}

export const COUNTRIES_FR = {
  "AF": "Afghanistan",
  "AL": "Albanie",
  "DZ": "Algérie",
  "AS": "Samoa américaine",
  "AD": "Andorre",
  "AO": "Angola",
  "AI": "Anguilla",
  "AQ": "Antarctique",
  "AG": "Antigua et Barbuda",
  "AR": "Argentine",
  "AM": "Arménie",
  "AW": "Aruba",
  "AU": "Australie",
  "AT": "Autriche",
  "AZ": "Azerbaidjan",
  "BS": "Bahamas",
  "BH": "Bahrein",
  "BD": "Bangladesh",
  "BB": "Barbade",
  "BY": "Bielorussie",
  "BE": "Belgique",
  "BZ": "Belize",
  "BJ": "Bénin",
  "BM": "Bermudes",
  "BT": "Bhoutan",
  "BO": "Bolivie",
  "BA": "Bosnie-Herzégovine",
  "BW": "Botswana",
  "BV": "Île Bouvet",
  "BR": "Brésil",
  "IO": "Océan Indien Britannique",
  "BN": "Brunei Darussalam",
  "BG": "Bulgarie",
  "BF": "Burkina Faso",
  "BI": "Burundi",
  "KH": "Cambodge",
  "CM": "Cameroun",
  "CA": "Canada",
  "CV": "Cap-Vert",
  "KY": "Caïmanes",
  "CF": "Centrafricaine, République",
  "TD": "Tchad",
  "CL": "Chili",
  "CN": "Chine",
  "CX": "Île Christmas",
  "CC": "Cocos",
  "CO": "Colombie",
  "KM": "Comores",
  "CG": "Congo, République populaire",
  "CD": "Congo, République démocratique",
  "CK": "Îles Cook",
  "CR": "Costa Rica",
  "CI": "Côte-d'Ivoire",
  "HR": "Croatie",
  "CU": "Cuba",
  "CY": "Chypre",
  "CZ": "Tchéquie",
  "DK": "Danemark",
  "DJ": "Djibouti",
  "DM": "Dominique",
  "DO": "République Dominicaine",
  "EC": "Équateur",
  "EG": "Égypte",
  "SV": "El Salvador",
  "GQ": "Guinée équatoriale",
  "ER": "Érythrée",
  "EE": "Estonie",
  "ET": "Éthiopie",
  "FK": "Îles Malouines",
  "FO": "Îles Féroé",
  "FJ": "Fidji",
  "FI": "Finlande",
  "FR": "France",
  "GF": "Guyane française",
  "PF": "Polynésie française",
  "TF": "Terres australes françaises",
  "GA": "Gabon",
  "GM": "Gambie",
  "GE": "Géorgie",
  "DE": "Allemagne",
  "GH": "Ghana",
  "GI": "Gibraltar",
  "GR": "Grèce",
  "GL": "Groenland",
  "GD": "Grenada",
  "GP": "Guadeloupe",
  "GU": "Guam",
  "GT": "Guatemala",
  "GN": "Guinée",
  "GW": "Guinée-Bissau",
  "GY": "Guyana",
  "HT": "Haïti",
  "HM": "Îles Heard-et-MacDonald",
  "VA": "Saint-Siège",
  "HN": "Honduras",
  "HK": "Hong Kong",
  "HU": "Hongrie",
  "IS": "Islande",
  "IN": "Inde",
  "ID": "Indonésie",
  "IR": "Iran",
  "IQ": "Irak",
  "IE": "Irlande",
  "IL": "Israël",
  "IT": "Italie",
  "JM": "Jamaïque",
  "JP": "Japon",
  "JO": "Jordanie",
  "KZ": "Kazakhstan",
  "KE": "Kenya",
  "KI": "Kiribati",
  "KP": "Corée du Nord, République populaire démocratique",
  "KR": "Corée du Sud, République",
  "KW": "Koweit",
  "KG": "Kirghistan",
  "LA": "Laos",
  "LV": "Lettonie",
  "LB": "Liban",
  "LS": "Lesotho",
  "LR": "Libéria",
  "LY": "Libye",
  "LI": "Liechtenstein",
  "LT": "Lituanie",
  "LU": "Luxembourg, Grand-Duché",
  "MO": "Macao",
  "MK": "Macédoine, Ex-République yougoslave",
  "MG": "Madagascar",
  "MW": "Malawi",
  "MY": "Malaisie",
  "MV": "Maldives",
  "ML": "Mali",
  "MT": "Malte",
  "MH": "Îles Marshall",
  "MQ": "Martinique",
  "MR": "Mauritanie",
  "MU": "Maurice",
  "YT": "Mayotte",
  "MX": "Mexique",
  "FM": "Micronésie",
  "MD": "Moldavie",
  "MC": "Monaco",
  "MN": "Mongolie",
  "MS": "Montserrat",
  "MA": "Maroc",
  "MZ": "Mozambique",
  "MM": "Myanmar",
  "NA": "Namibie",
  "NR": "Nauru",
  "NP": "Népal",
  "NL": "Pays-Bas",
  "NC": "Nouvelle-Calédonie",
  "NZ": "Nouvelle-Zélande",
  "NI": "Nicaragua",
  "NE": "Niger",
  "NG": "Nigéria",
  "NU": "Niué",
  "NF": "Île Norfolk",
  "MP": "Mariannes du Nord",
  "NO": "Norvège",
  "OM": "Oman",
  "PK": "Pakistan",
  "PW": "Palau",
  "PS": "Palestine",
  "PA": "Panama",
  "PG": "Papouasie-Nouvelle-Guinée",
  "PY": "Paraguay",
  "PE": "Pérou",
  "PH": "Philippines",
  "PN": "Pitcairn",
  "PL": "Pologne",
  "PT": "Portugal",
  "PR": "Porto Rico",
  "QA": "Qatar",
  "RE": "Réunion",
  "RO": "Roumanie",
  "RU": "Russie",
  "RW": "Rwanda",
  "SH": "Sainte-Hélène",
  "KN": "Saint-Christophe-et-Niévès",
  "LC": "Sainte-Lucie",
  "PM": "Saint Pierre and Miquelon",
  "VC": "Saint-Vincent et les Grenadines",
  "WS": "Samoa",
  "SM": "Saint-Marin",
  "ST": "São Tomé et Principe",
  "SA": "Arabie Saoudite",
  "SN": "Sénégal",
  "SC": "Seychelles",
  "SL": "Sierra Leone",
  "SG": "Singapour",
  "SK": "Slovaquie",
  "SI": "Slovénie",
  "SB": "Salomon",
  "SO": "Somalie",
  "ZA": "Afrique du Sud",
  "GS": "Géorgie du Sud-et-les Îles Sandwich du Sud",
  "ES": "Espagne",
  "LK": "Sri Lanka",
  "SD": "Soudan",
  "SR": "Suriname",
  "SJ": "Svalbard et Île Jan Mayen",
  "SZ": "Ngwane, Royaume du Swaziland",
  "SE": "Suède",
  "CH": "Suisse",
  "SY": "Syrie",
  "TW": "Taïwan",
  "TJ": "Tadjikistan",
  "TZ": "Tanzanie, République unie",
  "TH": "Thaïlande",
  "TL": "Timor Leste",
  "TG": "Togo",
  "TK": "Tokelau",
  "TO": "Tonga",
  "TT": "Trinidad et Tobago",
  "TN": "Tunisie",
  "TR": "Turquie",
  "TM": "Turkménistan",
  "TC": "Îles Turques-et-Caïques",
  "TV": "Tuvalu",
  "UG": "Ouganda",
  "UA": "Ukraine",
  "AE": "Émirats Arabes Unis",
  "GB": "Royaume-Uni",
  "US": "États-Unis d'Amérique",
  "UM": "Îles mineures éloignées des États-Unis",
  "UY": "Uruguay",
  "UZ": "Ouzbékistan",
  "VU": "Vanuatu",
  "VE": "Venezuela",
  "VN": "Vietnam",
  "VG": "Îles vierges britanniques",
  "VI": "Îles vierges américaines",
  "WF": "Wallis et Futuna",
  "EH": "Sahara occidental",
  "YE": "Yémen",
  "ZM": "Zambie",
  "ZW": "Zimbabwe",
  "AX": "Åland",
  "BQ": "Bonaire, Saint-Eustache et Saba",
  "CW": "Curaçao",
  "GG": "Guernesey",
  "IM": "Île de Man",
  "JE": "Jersey",
  "ME": "Monténégro",
  "BL": "Saint-Barthélemy",
  "MF": "Saint-Martin (partie française)",
  "RS": "Serbie",
  "SX": "Saint-Martin (partie néerlandaise)",
  "SS": "Sud-Soudan",
  "XK": "Kosovo"
}

export const LANGUAGES_FR = {
  "ab": "abkhaze",
  "ace": "aceh",
  "ach": "acoli",
  "ada": "adangme",
  "ady": "adygh\u00e9en",
  "aa": "afar",
  "afh": "afrihili",
  "af": "afrikaans",
  "agq": "aghem",
  "ain": "a\u00efnou",
  "ak": "akan",
  "akk": "akkadien",
  "bss": "akoose",
  "akz": "Alabama",
  "sq": "albanais",
  "ale": "al\u00e9oute",
  "arq": "Algerian Arabic",
  "de": "allemand",
  "de_AT": "allemand autrichien",
  "de_CH": "allemand suisse",
  "alt": "alta\u00ef du Sud",
  "zgh": "amazighe standard marocain",
  "ase": "American Sign Language",
  "am": "amharique",
  "ang": "ancien anglais",
  "fro": "ancien fran\u00e7ais",
  "goh": "ancien haut allemand",
  "sga": "ancien irlandais",
  "anp": "angika",
  "en": "anglais",
  "en_US": "anglais am\u00e9ricain",
  "en_AU": "anglais australien",
  "en_GB": "anglais britannique",
  "en_CA": "anglais canadien",
  "njo": "Ao Naga",
  "ar": "arabe",
  "ar_001": "arabe standard moderne",
  "shu": "arabe tchadien",
  "an": "aragonais",
  "arc": "aram\u00e9en",
  "sam": "aram\u00e9en samaritain",
  "aro": "Araona",
  "arp": "arapaho",
  "arn": "araukan",
  "arw": "arawak",
  "hy": "arm\u00e9nien",
  "as": "assamais",
  "asa": "assou",
  "ast": "asturien",
  "cch": "atsam",
  "av": "avar",
  "ae": "avestique",
  "awa": "awadhi",
  "ay": "aymara",
  "az": "az\u00e9ri",
  "ba": "bachkir",
  "bfq": "Badaga",
  "ksf": "bafia",
  "bfd": "bafut",
  "bqi": "Bakhtiari",
  "ban": "balinais",
  "bal": "baloutchi",
  "bm": "bambara",
  "bax": "bamoun",
  "bjn": "Banjar",
  "nds": "bas-allemand",
  "dsb": "bas-sorabe",
  "eu": "basque",
  "bas": "bassa",
  "bbc": "Batak Toba",
  "bar": "Bavarian",
  "bej": "bedja",
  "bem": "bemba",
  "bez": "b\u00e9na",
  "bn": "bengali",
  "bew": "Betawi",
  "bho": "bhojpuri",
  "bi": "bichelamar",
  "be": "bi\u00e9lorusse",
  "bik": "bikol",
  "bin": "bini",
  "my": "birman",
  "bpy": "Bishnupriya",
  "byn": "blin",
  "brx": "bodo",
  "bs": "bosniaque",
  "bum": "boulou",
  "bua": "bouriate",
  "brh": "Brahui",
  "bra": "braj",
  "br": "breton",
  "bug": "bugi",
  "bg": "bulgare",
  "cad": "caddo",
  "frc": "Cajun French",
  "yue": "cantonais",
  "cps": "Capiznon",
  "kea": "capverdien",
  "krl": "car\u00e9lien",
  "car": "caribe",
  "ca": "catalan",
  "cay": "cayuga",
  "ceb": "cebuano",
  "dtp": "Central Dusun",
  "esu": "Central Yupik",
  "ksb": "chambala",
  "ch": "chamorro",
  "chr": "cherokee",
  "chy": "cheyenne",
  "chb": "chibcha",
  "qug": "Chimborazo Highland Quichua",
  "zh": "chinois",
  "zh_Hans": "chinois simplifi\u00e9",
  "zh_Hant": "chinois traditionnel",
  "chp": "chipewyan",
  "shi": "chleuh",
  "cho": "choctaw",
  "chk": "chuuk",
  "si": "cinghalais",
  "swb": "comorien",
  "cop": "copte",
  "ko": "cor\u00e9en",
  "kw": "cornique",
  "co": "corse",
  "cr": "cree",
  "mus": "creek",
  "mfe": "cr\u00e9ole mauricien",
  "hr": "croate",
  "dak": "dakota",
  "da": "danois",
  "dar": "dargwa",
  "dzg": "dazaga",
  "del": "delaware",
  "din": "dinka",
  "dyo": "diola-fogny",
  "dyu": "dioula",
  "doi": "dogri",
  "dgr": "dogrib",
  "dua": "douala",
  "dz": "dzongkha",
  "sco": "\u00e9cossais",
  "efi": "efik",
  "arz": "Egyptian Arabic",
  "egy": "\u00e9gyptien ancien",
  "eka": "ekajuk",
  "elx": "\u00e9lamite",
  "ebu": "embou",
  "egl": "Emilian",
  "myv": "erzya",
  "es": "espagnol",
  "es_ES": "espagnol europ\u00e9en",
  "es_419": "espagnol latino-am\u00e9ricain",
  "es_MX": "espagnol mexicain",
  "eo": "esp\u00e9ranto",
  "et": "estonien",
  "ee": "\u00e9w\u00e9",
  "ewo": "\u00e9wondo",
  "ext": "Extremaduran",
  "fan": "fang",
  "fat": "fanti",
  "fo": "f\u00e9ro\u00efen",
  "fj": "fidjien",
  "hif": "Fiji Hindi",
  "fil": "filipino",
  "fi": "finnois",
  "nl_BE": "flamand",
  "fon": "fon",
  "gur": "Frafra",
  "fr": "fran\u00e7ais",
  "fr_CA": "fran\u00e7ais canadien",
  "fr_CH": "fran\u00e7ais suisse",
  "ksh": "francique ripuaire",
  "frp": "franco-proven\u00e7al",
  "fur": "frioulan",
  "frr": "frison du Nord",
  "fy": "frison occidental",
  "frs": "frison oriental",
  "gaa": "ga",
  "gd": "ga\u00e9lique \u00e9cossais",
  "gag": "gagaouze",
  "gl": "galicien",
  "cy": "gallois",
  "gan": "Gan Chinese",
  "lg": "ganda",
  "gay": "gayo",
  "gba": "gbaya",
  "ka": "g\u00e9orgien",
  "aln": "Gheg Albanian",
  "bbj": "ghomala",
  "glk": "Gilaki",
  "gil": "gilbertais",
  "gom": "Goan Konkani",
  "gon": "gondi",
  "gor": "gorontalo",
  "got": "gotique",
  "grb": "grebo",
  "el": "grec",
  "grc": "grec ancien",
  "kl": "groenlandais",
  "gn": "guarani",
  "gez": "gu\u00e8ze",
  "gu": "gujarati",
  "guz": "gusii",
  "gwi": "gwich\u02bcin",
  "hai": "haida",
  "ht": "ha\u00eftien",
  "hak": "Hakka Chinese",
  "ha": "haoussa",
  "hsb": "haut-sorabe",
  "haw": "hawa\u00efen",
  "he": "h\u00e9breu",
  "hz": "h\u00e9r\u00e9ro",
  "hil": "hiligaynon",
  "hi": "hindi",
  "ho": "hiri motu",
  "hit": "hittite",
  "hmn": "hmong",
  "hu": "hongrois",
  "hup": "hupa",
  "sah": "iakoute",
  "iba": "iban",
  "ibb": "ibibio",
  "io": "ido",
  "ig": "igbo",
  "ilo": "ilokano",
  "id": "indon\u00e9sien",
  "inh": "ingouche",
  "izh": "Ingrian",
  "ia": "interlingua",
  "ie": "interlingue",
  "iu": "inuktitut",
  "ik": "inupiaq",
  "ga": "irlandais",
  "is": "islandais",
  "it": "italien",
  "jam": "Jamaican Creole English",
  "ja": "japonais",
  "chn": "jargon chinook",
  "jv": "javanais",
  "kaj": "jju",
  "jrb": "jud\u00e9o-arabe",
  "jpr": "jud\u00e9o-persan",
  "jut": "Jutish",
  "quc": "k\u2019iche\u2019",
  "kbd": "kabardin",
  "kab": "kabyle",
  "kac": "kachin",
  "csb": "kachoube",
  "kgp": "Kaingang",
  "kkj": "kako",
  "kln": "kalenjin",
  "xal": "kalmouk",
  "kam": "kamba",
  "kbl": "kanembou",
  "kn": "kannada",
  "kr": "kanouri",
  "kaa": "karakalpak",
  "krc": "karatcha\u00ef balkar",
  "ks": "kashmiri",
  "kaw": "kawi",
  "kk": "kazakh",
  "ken": "Kenyang",
  "kha": "khasi",
  "km": "khmer",
  "kho": "khotanais",
  "khw": "Khowar",
  "cgg": "kiga",
  "ki": "kikuyu",
  "kmb": "kiMboundou",
  "krj": "Kinaray-a",
  "ky": "kirghize",
  "kiu": "Kirmanjki",
  "tlh": "klingon",
  "bkm": "kom",
  "kv": "komi",
  "koi": "komi-permiak",
  "kg": "kongo",
  "kok": "konkani",
  "kfo": "koro",
  "avk": "Kotava",
  "kum": "koumyk",
  "khq": "koyra chiini",
  "ses": "koyraboro senni",
  "kpe": "kpell\u00e9",
  "kri": "Krio",
  "kj": "kuanyama",
  "ku": "kurde",
  "kru": "kurukh",
  "kos": "kusaien",
  "kut": "kutenai",
  "nmg": "kwasio",
  "lad": "ladino",
  "lah": "lahnda",
  "lkt": "lakota",
  "lam": "lamba",
  "lag": "langi",
  "und": "langue ind\u00e9termin\u00e9e",
  "lo": "lao",
  "ltg": "Latgalian",
  "la": "latin",
  "lzz": "Laz",
  "lv": "letton",
  "lez": "lezghien",
  "lij": "Ligurian",
  "li": "limbourgeois",
  "ln": "lingala",
  "lfn": "Lingua Franca Nova",
  "lzh": "Literary Chinese",
  "lt": "lituanien",
  "liv": "Livonian",
  "jbo": "lojban",
  "lmo": "Lombard",
  "sli": "Lower Silesian",
  "loz": "lozi",
  "lu": "luba-katanga",
  "lua": "luba-lulua",
  "lui": "luiseno",
  "lun": "lunda",
  "luo": "luo",
  "lus": "lushai",
  "lb": "luxembourgeois",
  "mde": "maba",
  "mk": "mac\u00e9donien",
  "jmc": "machame",
  "mad": "madurais",
  "maf": "mafa",
  "mag": "magahi",
  "vmf": "Main-Franconian",
  "mai": "maithili",
  "mak": "makassar",
  "mgh": "makhuwa-meetto",
  "kde": "makonde",
  "ms": "malais",
  "ml": "malayalam",
  "dv": "maldivien",
  "mg": "malgache",
  "mt": "maltais",
  "mdr": "mandar",
  "mnc": "mandchou",
  "man": "mandingue",
  "mni": "manipuri",
  "gv": "manx",
  "mi": "maori",
  "mr": "marathe",
  "chm": "mari",
  "mh": "marshall",
  "mwr": "marwar\u00ee",
  "mas": "masai",
  "mzn": "Mazanderani",
  "byv": "medumba",
  "men": "mend\u00e9",
  "mwv": "Mentawai",
  "mer": "merou",
  "mgo": "m\u00e9ta\u2019",
  "mic": "micmac",
  "nan": "Min Nan Chinese",
  "min": "minangkabau",
  "xmf": "Mingrelian",
  "mwl": "mirandais",
  "moh": "mohawk",
  "mdf": "moksa",
  "ro_MD": "moldave",
  "lol": "mongo",
  "mn": "mongol",
  "mos": "mor\u00e9",
  "ary": "Moroccan Arabic",
  "enm": "moyen anglais",
  "frm": "moyen fran\u00e7ais",
  "gmh": "moyen haut-allemand",
  "mga": "moyen irlandais",
  "dum": "moyen n\u00e9erlandais",
  "mul": "multilingue",
  "mua": "mundang",
  "ttt": "Muslim Tat",
  "mye": "my\u00e8n\u00e8",
  "nqo": "n\u2019ko",
  "naq": "nama",
  "nap": "napolitain",
  "na": "nauruan",
  "nv": "navaho",
  "nd": "nd\u00e9b\u00e9l\u00e9 du Nord",
  "nr": "nd\u00e9b\u00e9l\u00e9 du Sud",
  "ng": "ndonga",
  "nl": "n\u00e9erlandais",
  "ne": "n\u00e9palais",
  "new": "newari",
  "nwc": "newar\u00ee classique",
  "sba": "ngambay",
  "nnh": "ngiemboon",
  "jgo": "ngomba",
  "yrl": "Nheengatu",
  "nia": "nias",
  "niu": "niu\u00e9",
  "nog": "noga\u00ef",
  "no": "norv\u00e9gien",
  "nb": "norv\u00e9gien bokm\u00e5l",
  "nn": "norv\u00e9gien nynorsk",
  "nov": "Novial",
  "nus": "nuer",
  "nym": "nyamwezi",
  "ny": "nyanja",
  "nyn": "nyankol\u00e9",
  "nyo": "nyoro",
  "nzi": "nzema",
  "oc": "occitan",
  "oj": "ojibwa",
  "luy": "oluluyia",
  "or": "oriya",
  "om": "oromo",
  "osa": "osage",
  "os": "oss\u00e8te",
  "udm": "oudmourte",
  "uga": "ougaritique",
  "ug": "ou\u00efghour",
  "ur": "ourdou",
  "uz": "ouzbek",
  "ps": "pachto",
  "pal": "pahlavi",
  "pfl": "Palatine German",
  "pau": "palau",
  "pi": "pali",
  "pam": "pampangan",
  "pag": "pangasinan",
  "pap": "papiamento",
  "pa": "pendjabi",
  "pdc": "Pennsylvania German",
  "fa": "persan",
  "peo": "persan ancien",
  "ff": "peul",
  "phn": "ph\u00e9nicien",
  "pcd": "Picard",
  "pms": "Piedmontese",
  "pdt": "Plautdietsch",
  "pon": "pohnpei",
  "pl": "polonais",
  "pnt": "Pontic",
  "pt": "portugais",
  "pt_BR": "portugais br\u00e9silien",
  "pt_PT": "portugais europ\u00e9en",
  "pro": "proven\u00e7al ancien",
  "prg": "Prussian",
  "qu": "quechua",
  "root": "racine",
  "raj": "rajasthani",
  "rap": "rapanui",
  "rar": "rarotongien",
  "rif": "Riffian",
  "rgn": "Romagnol",
  "rm": "romanche",
  "rof": "rombo",
  "rtm": "Rotuman",
  "ro": "roumain",
  "rn": "roundi",
  "rug": "Roviana",
  "ru": "russe",
  "rue": "Rusyn",
  "rwk": "rwa",
  "rw": "rwanda",
  "ssy": "saho",
  "saq": "samburu",
  "smn": "sami d\u2019Inari",
  "smj": "sami de Lule",
  "se": "sami du Nord",
  "sma": "sami du Sud",
  "sms": "sami skolt",
  "sm": "samoan",
  "sgs": "Samogitian",
  "sad": "sandawe",
  "sg": "sangho",
  "sbp": "sangu",
  "zxx": "sans contenu linguistique",
  "sa": "sanskrit",
  "sat": "santal",
  "sc": "sarde",
  "sas": "sasak",
  "sdc": "Sassarese Sardinian",
  "stq": "Saterland Frisian",
  "saz": "Saurashtra",
  "sly": "Selayar",
  "sel": "selkoupe",
  "seh": "sena",
  "see": "seneca",
  "sr": "serbe",
  "sh": "serbo-croate",
  "srr": "s\u00e9r\u00e8re",
  "sei": "Seri",
  "st": "sesotho",
  "shn": "shan",
  "sn": "shona",
  "scn": "sicilien",
  "sid": "sidamo",
  "bla": "siksika",
  "szl": "Silesian",
  "sd": "sindhi",
  "den": "slavey",
  "cu": "slavon d\u2019\u00e9glise",
  "sk": "slovaque",
  "sl": "slov\u00e8ne",
  "xog": "soga",
  "sog": "sogdien",
  "so": "somali",
  "snk": "sonink\u00e9",
  "ckb": "sorani",
  "nso": "sotho du Nord",
  "su": "soundanais",
  "sus": "soussou",
  "azb": "South Azerbaijani",
  "srn": "sranan tongo",
  "sv": "su\u00e9dois",
  "gsw": "suisse allemand",
  "suk": "sukuma",
  "sux": "sum\u00e9rien",
  "sw": "swahili",
  "swc": "swahili du Congo",
  "ss": "swati",
  "zbl": "symboles Bliss",
  "syr": "syriaque",
  "syc": "syriaque classique",
  "tg": "tadjik",
  "tl": "tagalog",
  "ty": "tahitien",
  "dav": "taita",
  "tly": "Talysh",
  "tmh": "tamacheq",
  "tzm": "tamazight",
  "ta": "tamoul",
  "trv": "taroko",
  "twq": "tasawaq",
  "tt": "tatar",
  "chg": "tchaghata\u00ef",
  "cs": "tch\u00e8que",
  "ce": "tch\u00e9tch\u00e8ne",
  "cv": "tchouvache",
  "te": "t\u00e9lougou",
  "tem": "temne",
  "ter": "tereno",
  "teo": "teso",
  "tet": "tetum",
  "th": "tha\u00ef",
  "bo": "tib\u00e9tain",
  "tig": "tigr\u00e9",
  "ti": "tigrigna",
  "tiv": "tiv",
  "tli": "tlingit",
  "tpi": "tok pisin",
  "tkl": "tokelau",
  "tog": "tonga nyasa",
  "to": "tonguien",
  "fit": "Tornedalen Finnish",
  "tyv": "touva",
  "tkr": "Tsakhur",
  "tsd": "Tsakonian",
  "tsi": "tsimshian",
  "ts": "tsonga",
  "tn": "tswana",
  "tcy": "Tulu",
  "tum": "tumbuka",
  "aeb": "Tunisian Arabic",
  "tr": "turc",
  "crh": "turc de Crim\u00e9e",
  "ota": "turc ottoman",
  "tk": "turkm\u00e8ne",
  "tru": "Turoyo",
  "tvl": "tuvalu",
  "tw": "twi",
  "kcg": "tyap",
  "rom": "tzigane",
  "uk": "ukrainien",
  "umb": "umbundu",
  "vai": "va\u00ef",
  "rup": "valaque",
  "ve": "venda",
  "vec": "Venetian",
  "vep": "Veps",
  "vi": "vietnamien",
  "non": "vieux norrois",
  "vo": "volapuk",
  "vro": "V\u00f5ro",
  "vot": "vote",
  "vun": "vunjo",
  "wal": "walamo",
  "wa": "wallon",
  "wae": "walser",
  "war": "waray",
  "wbp": "Warlpiri",
  "was": "washo",
  "guc": "Wayuu",
  "vls": "West Flemish",
  "mrj": "Western Mari",
  "wo": "wolof",
  "wuu": "Wu Chinese",
  "xh": "xhosa",
  "hsn": "Xiang Chinese",
  "yav": "yangben",
  "yao": "yao",
  "yap": "yapois",
  "ybb": "yemba",
  "ii": "yi du Sichuan",
  "yi": "yiddish",
  "yo": "yoruba",
  "zap": "zapot\u00e8que",
  "dje": "zarma",
  "zza": "zazaki",
  "zea": "Zeelandic",
  "zen": "zenaga",
  "za": "zhuang",
  "gbz": "Zoroastrian Dari",
  "zu": "zoulou",
  "zun": "zuni"
}

export const ORDER = {
  'a-z': 'a-z',
  'avg_rating': 'Note moyenne',
  'rating_number': 'Nombre de votes',
  'release_date': 'Dernières sorties',
  'coming_soon': 'Prochaines sorties',
}