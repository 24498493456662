import {combineReducers} from 'redux'
import {
  LOG_IN_FULFILLED, LOG_IN_REJECTED, LOG_IN_SENT,
  LOG_OUT_FULFILLED, LOG_OUT_REJECTED,
  FETCHING_USER_REQUEST_FULFILLED, FETCHING_USER_REQUEST_REJECTED,
  SIGN_UP_FULFILLED, SIGN_UP_REJECTED,
  RECOMMENDED_MOVIES_REQUEST_FULFILLED, RECOMMENDED_MOVIES_REQUEST_REJECTED 
} from './actions'

const merge = (prev, next) => Object.assign({}, prev, next)

const userReducer = (state = {}, action) => {
  switch(action.type) {
    case LOG_IN_FULFILLED:
      return merge(state, {token: action.payload})
    case LOG_IN_REJECTED:
      return merge(state, {loginError: action.payload})
    case SIGN_UP_FULFILLED:
      return merge(state, {token: action.payload})
    case SIGN_UP_REJECTED:
      return merge(state, {signupError: action.payload})
    case LOG_OUT_FULFILLED:
      return merge(state, {user: undefined, token: null})
    case FETCHING_USER_REQUEST_FULFILLED:
      return merge(state, {user: action.payload})
    case FETCHING_USER_REQUEST_REJECTED:
      return merge(state, {userFetchingError: action.error})
    default:
      return state
  }
}

const recommendedMoviesReducer = (state = {}, action) => {
  switch(action.type) {
    case RECOMMENDED_MOVIES_REQUEST_FULFILLED:
      return merge(state, {recommendedMovies: action.payload})
    case RECOMMENDED_MOVIES_REQUEST_REJECTED:
      return merge(state, {error: action.error})
    default:
      return state
  }
}

export default combineReducers({
  user: userReducer,
  recommendedMovies: recommendedMoviesReducer,
})