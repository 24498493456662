import {url, getToken} from '../api'
import { getI18nextLanguage, getWatchlistFromLocalstorage } from '../utils/localStorage'

export const getWatchlist = async (page = 1) => {
    const token = await getToken()
    const language = await getI18nextLanguage()
    const headers = {'content-type': 'application/json'}
    if (token !== null) {
        headers['Authorization'] = `Token ${token}`
    }
    const response = await fetch(`${url}/watchlist/?page=${page}&language=${language}`, {
      headers: headers,
      method: 'GET',
    })

    if (response.ok) {
      const result = await response.json()
      return result
    }
  
    const errMessage = await response.json()
    throw new Error(JSON.stringify(errMessage))  
}

export const addWatchlist = async (movieTranslationId) => {
    const token = await getToken()
    const headers = {'content-type': 'application/json'}
    if (token !== null) {
        headers['Authorization'] = `Token ${token}`
    }
    const response = await fetch(`${url}/watchlist/`, {
        headers: headers,
        method: 'POST',
        body: JSON.stringify({'translation_id': movieTranslationId}),
    })

    if (response.ok) {
        const result = await response.json()
        return result
    }

    const errMessage = await response.json()
    // Jsonify and parse later because error.messages will apply .toString()
    throw new Error(JSON.stringify(errMessage))       
}


export const deleteWatchlist = async (movieTranslationId) => {
    const token = await getToken()
    const response = await fetch(`${url}/watchlist/`, {
        headers: {
            'content-type': 'application/json',
            'Authorization': `Token ${token}`,
        },
        method: 'DELETE',
        body: JSON.stringify({'translation_id': movieTranslationId}),
    })
    
    if (response.ok) {
        return true
    }
    const errMessage = await response.json()
    throw new Error(JSON.stringify(errMessage))  
}

export const syncWatchlist = async () => {
    const token = await getToken()
    const watchlist = await getWatchlistFromLocalstorage() 
    const movieTranslationIds = watchlist.map(i => i.id)
    const response = await fetch(`${url}/watchlist/sync/`, {
        headers: {
        'content-type': 'application/json',
        'Authorization': `Token ${token}`,
        },
        method: 'POST',
        body: JSON.stringify({'translation_ids': movieTranslationIds}),
    })

    if (response.ok) {
        const result = await response.json()
        return result
    }

    const errMessage = await response.json()
    // Jsonify and parse later because error.messages will apply .toString()
    throw new Error(JSON.stringify(errMessage))       
}

